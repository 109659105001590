import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import { ImageModule } from "../../modules/Image_Module";
import RightsideNegotiator from "../../modules/RightsideNegotiator";
import { youtube_parser } from "../common/utils";
import { useStaticQuery, graphql } from "gatsby"
import * as Icon from 'react-bootstrap-icons';
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router";

// Images
// styles
import "./MemberCard.scss"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share"

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share"


import { propTypes } from "react-bootstrap/esm/Image";
import { Container } from "react-bootstrap";
// markup

const MemberCard = (props) => {
  const [isPlay2, setPlay2] = useState(false);
  const [videoId, setVideoId] = useState('');

  let shareurl = '';
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : '';
  }

  const [Shareicons, setShareicons] = useState(false);
  const [render, setRender] = useState(false);
  useEffect(() =>{
    if(!render){
      setRender(true)
    }
  })


  const openShareicons = () => {
    setShareicons(true);
  }

  const closeShareicons = () => {
    setShareicons(false);
  }

  const OpenModalVideo2 = (video_id) => {
    setVideoId(video_id);
    setPlay2(true)
  }

  const trackerShare = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' + shareurl,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }

  const data = useStaticQuery(graphql`
    query SiteDataQuery {

      glstrapi {
        siteConfig {
          Youtube_Link
        }
      }

    }
  `)

  var site_data = data.glstrapi.siteConfig;

  //console.log("props.RightSideModule", props.RightSideModule);

  var youtube_id = props.RightSideModule && props.RightSideModule.Embed_Video_URL ? youtube_parser(props.RightSideModule.Embed_Video_URL) : '';

  //console.log("RightSideModule", props.Office, props.RightSideModule);

   //console.log ("memberprops", props)


  return (
    <>
    { render && props.template === "common" && props.page.Layout === "Rightside_Block" && props.page.Alias.includes("-guide") &&
    <Helmet>
      <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "VideoObject",
          "name" : "${props?.MetaTitle}" ,
          "url" : "${props?.location?.href}",
          "description" : "${props?.page?.Meta_Description}" ,
          "thumbnailUrl" : "${`https://img.youtube.com/vi/${youtube_id}/0.jpg`}",
          "uploadDate" : "2021-07-07",
          "duration" : "PT01M05S",
          "contentUrl" :  "${`https://www.youtube.com/watch?v=${youtube_id}&t=15s`}",
          "embedUrl": "${`https://www.youtube.com/embed/${youtube_id}`}",
          "interactionStatistic" :{
            "@type": "InteractionCounter",
            "interactionType": { "@type": "http://schema.org/WatchAction" },
            "userInteractionCount": 127
          }
}`}</script>
     </Helmet>}
      {props.RightSideModule ? <>

        {(props.RightSideModule.RightsideImage || props.RightSideModule.Embed_Video_URL) &&
          <picture className="image_cnt">
            {/* <ImageModule ImageSrc={props.RightSideModule.RightsideImage} classNames={"sec-logobg2"} /> */} 
            <img className={'sec-logobg2'} src={`https://img.youtube.com/vi/${youtube_id}/0.jpg`} alt="" />
            {
              isPlay2 ? (
                <>
                  <a href="javascript:;" onClick={() => setPlay2(false)} className="clse_btn">X</a>
                  <ReactPlayer className="sidebar_video" url={videoId} modestbranding="1" controls={false} autoplay={true} muted={false} playsinline={true} loop={true} playing={true} width='100%' height='100%'></ReactPlayer>
                </>
              ) : (
                <>

                  {
                    props.RightSideModule.Embed_Video_URL && (

                      <strong className="play-btn-icon" onClick={() => OpenModalVideo2(props.RightSideModule.Embed_Video_URL)}>
                        <i className="icon-play-btn"></i>
                      </strong>
                    )
                  }
                </>
              )
            }


          </picture>
        }
        <div className={`tenant-details text-left ${props.RightSideModule.RightsideImage || props.RightSideModule.Embed_Video_URL ? 'no_bdrad' : ''}`}>

          <div className="wrp">

            <h4>{props.RightSideModule.Title}</h4>
            <p className="desc">
              {props.RightSideModule.Content}
            </p>
            <div className="">
              {
                (props.RightSideModule.CTA_1_URL === "mortgage_advisor" || props.RightSideModule.CTA_1_URL === "moving_home") ?

                  props.RightSideModule.CTA_1_Label && <a href="javascript:;" className="btn btn-primary" onClick={(e) => props.openModalForm(e, props.RightSideModule.CTA_1_Label, props.RightSideModule.CTA_1_URL)}><span>{props.RightSideModule.CTA_1_Label}</span></a>
                  :
                  props.RightSideModule.CTA_1_Label && <Link to={props.RightSideModule.CTA_1_URL} className="btn btn-primary"><span>{props.RightSideModule.CTA_1_Label}</span></Link>
              }

              {
                props.RightSideModule.CTA_2_Label && props.RightSideModule.CTA_2_URL !== "/contact-us" && <Link to={props.RightSideModule.CTA_2_URL} className="btn btn-outline btn-outline--white" state={{ prevPath: props.location ? props.location.pathname : '' }}><span>{props.RightSideModule.CTA_2_Label}</span></Link>
              }
            </div>
            <div className="share-row">
              <a href="javascript:;" onClick={openShareicons}> <i className="icon-share-white"></i> <span>Share</span></a>

              {Shareicons &&
                <div onMouseEnter={openShareicons} onMouseLeave={closeShareicons} className="property-share-social-icons mb_dev">
                  <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                    <FacebookIcon size={32} round={false} borderRadius={`10`} />
                  </FacebookShareButton>
                  <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                    <TwitterIcon size={32} round={false} borderRadius={`10`} />
                  </TwitterShareButton>
                  <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                    <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                  </LinkedinShareButton>
                  <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                    <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                  </WhatsappShareButton>
                </div>
              }
              {/* <Link href="#"> <i className="icon-print"></i> <span>Print</span></Link> */}

              <a href={site_data.Youtube_Link} target="_blank"> <Icon.Youtube className="icon_cls" width="30px" height="24px" /> <span>Subscribe</span></a>
            </div>

            <RightsideNegotiator Office={props.RightSideModule.Choose_Office ? props.RightSideModule.Choose_Office : props.RightSideModule.Choose_Peoples} WhatsappText={props.WhatsappText} />
          </div>
        </div>

      </> : <>

        <div className="tenant-details text-left">
          {/* <img src={secLogoBg2} className="sec-logobg2" /> */}
          <div className="wrp">

            <h4>{props.title}</h4>
            <p className="desc">
              {props.content}
            </p>
            <div className="">
              {
                props.btnText && (props.btnText === "Apply for this Job" || props.btnLink === "request_details" || props.btnLink === "book_a_viewing") ?
                  <a href={"javascript:;"} onClick={props.openModalForm} className="btn btn-primary"><span> {props.btnText}</span></a>
                  :
                  props.btnText && <Link to={props.btnLink} className="btn btn-primary"><span> {props.btnText}</span></Link>
              }

              {props.btnTextSecondary && <Link to={props.btnLinkSecondary} className="btn btn-outline btn-outline--white" state={{ prevPath: props.location ? props.location.pathname : '' }}><span>{props.btnTextSecondary}</span></Link>}
            </div>
            <div className="share-row">
              <a href="javascript:;" onClick={openShareicons}> <i className="icon-share-white"></i> <span>Share</span></a>

              {Shareicons &&
                <div onMouseEnter={openShareicons} onMouseLeave={closeShareicons} className="property-share-social-icons mb_dev">
                  <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                    <FacebookIcon size={32} round={false} borderRadius={`10`} />
                  </FacebookShareButton>
                  <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                    <TwitterIcon size={32} round={false} borderRadius={`10`} />
                  </TwitterShareButton>
                  <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                    <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                  </LinkedinShareButton>
                  <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                    <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                  </WhatsappShareButton>
                </div>
              }


              {/* <Link href="#"> <i className="icon-print"></i> <span>Print</span></Link> */}
              <a href={site_data.Youtube_Link} target="_blank"> <Icon.Youtube className="icon_cls" width="30px" height="24px" /> <span>Subscribe</span></a>
            </div>

            <RightsideNegotiator Office={props.Office} WhatsappText={props.WhatsappText} />
          </div>
        </div>


      </>
      }
    </>
  )
}

export default MemberCard
