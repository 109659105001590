import React, { useEffect, useState } from "react"
import { ImageModule } from "./Image_Module";
import { Link } from "@StarberryUtils";
import Modal from "react-bootstrap/Modal"
import MemberForm from "../components/forms/member-form"
import * as Icon from 'react-bootstrap-icons';
import logo from '../images/favicon.png'


const RightSideNego = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    let Name = props?.Office?.Name;
    return (
        props.Office ? <>
            <div className="negotiator d-flex justify-content-start align-items-center">
                {
                    <>
                        <figure>
                            {props.Office.Image && (Name != "Riya" ? <ImageModule ImageSrc={props.Office.Image} /> : <img src={logo} alt="profile" />)}
                        </figure>
                        <div className="info">
                            {Name != "Riya" && <strong className="name d-block"><Link to={`/about/our-people/${props.Office.URL}`}>{props.Office.Name}</Link></strong>}
                            <div className="contact-nav">
                                <div className="mob_whatsapp">
                                    <a href={`tel:${props.Office.Direct_Phone}`}><Icon.Telephone />  </a>
                                    {
                                        props.Office.Whatsapp_No && (
                                            <a href={`https://api.whatsapp.com/send/?phone=${props.Office.Whatsapp_No}&text=${props.WhatsappText ? props.WhatsappText : ''}&app_absent=0`} target="_blank">
                                                <Icon.Whatsapp />
                                            </a>
                                        )
                                    }
                                    <a href={"javascript:;"} onClick={() => setIsOpen(true)}><Icon.Envelope /> </a>
                                    {/* <a href={`tel:${props.Office.Phone}`}>{props.Office.Phone}</a> */}
                                </div>

                                {/* <span className="pipe_symbol">|</span> */}
                                {/* <a href={"javascript:;"} onClick={() => setIsOpen(true)}>Contact</a> */}
                                {/* {
                                props.Office.Whatsapp_No && (
                                    <a href={`https://api.whatsapp.com/send/?phone=${props.Office.Whatsapp_No}&text=${props.WhatsappText}&app_absent=0`} target="_blank">
                                        <Icon.Whatsapp /> {props.Office.Whatsapp_No}
                                    </a>
                                ) 
                            } */}
                                {/* <a href={"javascript:;"} onClick={() => setIsOpen(true)}><Icon.Envelope /> <div className="email_txt">{props.Office.Email}</div></a> */}

                            </div>
                        </div>
                    </>
                }
            </div>

            <Modal
                id="valuation_modal"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
            >
                {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}


                <Modal.Header closeButton className="contact-close-btn memberPopup">
                    <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {props.Office.Name}</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MemberForm to_email_id={props.Office.Email} formName={`Individual Contact`} formLabel={`Contact ${props.Office.Name}`} />
                </Modal.Body>
            </Modal>

        </> : <></>
    )
}

export default RightSideNego